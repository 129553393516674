import socials from '@/config/socials'
import networks from '@/config/networks'
import hooks from '@/config/hooks'

export default defineAppConfig({
  socials,
  networks,
  hooks,
  czkcTokenAddress: '0x07a2a7CCffD19e114c5309A90F63a43189cC3671',
  mockTransfer: false, 
})
