import * as chains from '@wagmi/core/chains'
import type { Network } from '~/types'

export default [
  {
    id: 'sepolia',
    icon: '/images/networks/sepolia-ethereum.svg',
    name: 'Sepolia - Ethereum testnet',
    RPC: 'https://sepolia.infura.io/v3/2027dccd6b614f1e84ee0ad1f3f1a714',
    faucetURL: 'https://www.google.com/search?q=ethereum+sepolia+faucet',
    chain: chains.sepolia,
    thresholds: {
      nativeToken: 0.1,
      czkcToken: 2_000_000,
    },
  },
  {
    id: 'arbitrum-sepolia',
    icon: '/images/networks/sepolia-arbitrum.svg',
    name: 'Sepolia - Arbitrum testnet',
    RPC: 'https://arbitrum-sepolia.infura.io/v3/2027dccd6b614f1e84ee0ad1f3f1a714',
    faucetURL: 'https://www.google.com/search?q=arbitrum+sepolia+faucet',
    chain: chains.arbitrumSepolia,
    thresholds: {
      nativeToken: 0.1,
      czkcToken: 2_000_000,
    },
  },
  {
    id: 'gnosis-chiado',
    icon: '/images/networks/chiado-gnosis.svg',
    name: 'Chiado - Gnosis Testnet',
    RPC: '',
    faucetURL: 'https://faucet.chiadochain.net/',
    chain: chains.gnosisChiado,
    thresholds: {
      nativeToken: 0.1,
      czkcToken: 2_000_000,
    },
  },
/*  {
    id: 'polygon-amoy',
    icon: '/images/networks/amoy-polygon.svg',
    name: 'Amoy - Polygon testnet',
    RPC: 'https://polygon-amoy.infura.io/v3/2027dccd6b614f1e84ee0ad1f3f1a714',
    faucetURL: 'https://faucet.polygon.technology',
    chain: chains.polygonAmoy,
    thresholds: {
      nativeToken: 0.1,
      czkcToken: 2_000_000,
    },
  },
  */
] as Network[]
